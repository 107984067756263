import React, { forwardRef } from 'react';
import { Text } from "@chakra-ui/react";
import range from 'lodash/range';
import { responsive } from './ThemeProvider/theme';

Text.Inline = forwardRef((props, ref) => <Text as="span" {...props} ref={ref} />);
Text.Bold = forwardRef((props, ref) => <Text fontWeight="700" {...props} ref={ref} />);
Text.Thin = forwardRef((props, ref) => <Text fontWeight="200" {...props} ref={ref} />);
Text.NotoSerif = forwardRef((props, ref) => <Text fontFamily="Noto Serif TC" fontWeight="700" {...props} ref={ref} />);

range(1, 7).forEach((key) => {
  Text[`H${key}`] = forwardRef((props, ref) => (
    <Text
      as={`h${key}`}
      fontSize={`${5 - key}xl`}
      {...props}
      ref={ref}
    />
  ));
});

Text.LoveP = forwardRef((props, ref) => <Text color="love.text" fontSize={responsive('1em', '1.25em')} letterSpacing="0.06em" fontWeight="black" {...props} ref={ref} />);
Text.WomenTitle = forwardRef((props, ref) => <Text.NotoSerif lineHeight="1" color="white" textShadow="1px 3px 12px #671D1D" fontSize={responsive('2.75em', '3em')} letterSpacing="0.06em" fontWeight="bold" {...props} ref={ref} />);

export default Text
