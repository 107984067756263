import React from 'react'

import Text from '../../components/Text'
import Flex from '../../components/Flex'
import DrawButton from '../../components/DrawButton'
import FullpageHeight from '../../components/FullpageHeight'
import useInstallQuestions from './useInstallQuestions'
// import DrawArrowButton from '../../components/DrawArrowButton'

const Start = ({ pageContext }) => {
  useInstallQuestions(pageContext)
  return (
    <FullpageHeight py="8" justifyContent="center">
      {/* <DrawArrowButton color="love.text" onClick={() => window.history.back()} /> */}
      <Flex width="100%" pb="1.625rem" justifyContent="center">
        <Text.LoveP fontWeight="bold" textAlign="center" fontSize="1.25rem">接下來將會有{pageContext.count}題題目，<br />預計花費時間為5分鐘</Text.LoveP>
      </Flex>
      <DrawButton mx="auto" to={pageContext.next}>開始測驗</DrawButton>
    </FullpageHeight>
  )
}

export default Start
