import { groupBy, random, range, reduce, sampleSize, shuffle } from 'lodash'
import { useEffect } from 'react'
import { useSessionStorage } from 'react-use'

import getCombination from '../../utils/getCombination'

const pick = (list = []) => {
  if (!list.length) return
  const index = random(list.length - 1)
  return list.splice(index, 1)[0]
}

const useInstallQuestions = (pageContext) => {
  const [iter] = useSessionStorage(`superquiz:love-languages:iter`)
  useEffect(() => {
    if (pageContext.questions) {
      const questionId = Object.keys(pageContext.questions)
      const allQuestions = Object.values(pageContext.questions).map(q => [...q.options])
      const allQuestionMap = allQuestions.map((qs) => qs.reduce((ma, q, j) => {
        ma[q] = j
        return ma
      }, {}))
      const arr = range(questionId.length)
      const comb = getCombination(arr)
      const questionList = shuffle([...comb, ...comb]).map(([a, b]) => {
        const aPicked = pick(allQuestions[a])
        const bPicked = pick(allQuestions[b])
        return [
          { value: `${questionId[a]}.${allQuestionMap[a][aPicked]}`, label: aPicked },
          { value: `${questionId[b]}.${allQuestionMap[b][bPicked]}`, label: bPicked },
        ]
      })
      const groupedPolor = groupBy(pageContext.polor, 'type')
      const polorQuestions = shuffle(reduce(groupedPolor, (all, polorQ) => {
        const ids = sampleSize(range(polorQ.length), 2)
        return all.concat(ids.map(index => ({ index, ...polorQ[index] })))
      }, []))
      const defaultQuestions = [
        questionList,
        polorQuestions,
      ]
      sessionStorage.setItem(`superquiz:love-languages:${pageContext.id}:${iter}`, JSON.stringify(defaultQuestions))
    }
  }, [iter])
  // useSessionStorage(storageKey, getDefaultQestions())
}

export default useInstallQuestions
